import React from 'react';
import './App.css';
import RTL from './helpers/rtl';
import Main from './components/Main/main'

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
  direction: 'rtl',
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App" dir="rtl">
        <RTL>
          <Main />
        </RTL>
      </div>
    </ThemeProvider>
  );
}

export default App;
