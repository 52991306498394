export const datesAreOnSameDay = (first, second) => 
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();


export const getHistoryBackForYesterday = (history) => {
    let position = 1;
    const lastSample = history[history.length - 1].timestamp.toDate();
    while(datesAreOnSameDay(lastSample, history[history.length - 1-position].timestamp.toDate())){
        position++;
    }

    return position;
}
