import React, { Component } from 'react';

import MaterialTable from 'material-table'

import { db } from '../../helpers/firebase';
import { getHistoryBackForYesterday } from '../../helpers/dates'

const columns = [
    { field: 'name', title: 'עיר' },
    { field: 'activeSick', type: 'numeric', title: 'חולים פעילים' },
    { field: 'newSickFromYesterday', type: 'numeric', title: 'חולים חדשים מאתמול' },
    { field: 'testLast7Days', type: 'numeric', title: 'בדיקות בשבוע האחרון' },
    { field: 'verifiedLast7Days', type: 'numeric', title: 'אחוז בדיקות חיוביות בשבוע האחרון' },
];

class Cities extends Component {
    state = { cities: null };

    componentDidMount() {
        const citiesRef = db.collection('cities');
        citiesRef.get().then((snapshot) => {
            const cities = [];
            snapshot.forEach((doc) => cities.push({ name: doc.id, ...doc.data() }));
            this.setState({ cities: cities });
        });
    }

    createData(city, samplesForYesterday) {
        const ret = {
            name: city.name,
            activeSick: city.history[city.history.length - 1].actualSick,
            newSickFromYesterday: city.history[city.history.length - 1].sickCount - city.history[city.history.length - samplesForYesterday - 1].sickCount,
            testLast7Days: city.history[city.history.length - 1].testLast7Days,
            verifiedLast7Days: city.history[city.history.length - 1].verifiedLast7Days * 100 / city.history[city.history.length - 1].testLast7Days,
            history: city.history
        };

        if (Number.isNaN(parseInt(ret.activeSick))) {
            ret.activeSick = 0;
        }

        if (Number.isNaN(ret.newSickFromYesterday)) {
            ret.newSickFromYesterday = 0;
        }

        if (Number.isNaN(ret.verifiedLast7Days)) {
            ret.verifiedLast7Days = 0;
        }

        ret.verifiedLast7Days = parseFloat(ret.verifiedLast7Days).toFixed(2);

        return ret;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.cities !== nextState.cities) {
            return true;
        }
        return false;
    }

    onSelectionChange(rows) {
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(rows);
        }
    }

    render() {
        const { cities } = this.state;

        let citiesView = [];
        if (cities) {
            const samplesForYesterday = getHistoryBackForYesterday(cities[0].history);
            citiesView = cities.map((e) => this.createData(e, samplesForYesterday)).sort((a, b) => b.activeSick - a.activeSick);
        }

        return <MaterialTable title="מצב ערים"
            localization={{
                toolbar: {
                    nRowsSelected: '{0} ערים נבחרו',
                    searchPlaceholder: 'חיפוש',
                    searchTooltip: 'חיפוש'
                }
            }}
            columns={columns}
            data={citiesView}
            options={{
                sorting: true,
                paging: false,
                maxBodyHeight: 300,
                headerStyle: { position: 'sticky', top: 0 },
                selection: true,
                showSelectAllCheckbox: false
            }}
            onSelectionChange={(rows) => this.onSelectionChange(rows)}
        />
    }
}

export default Cities;