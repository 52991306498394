
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import Cities from '../CitiesList/table';
import Chart from '../Charts/active.js';

import GoogleAd from '../Ads/google';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://digmi.org/">
                DiGMi
            </Link>
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const Main = (props) => {
    const classes = useStyles();
    const [selectedCities, setSelectedCities] = useState([]);

    return <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
            <Paper className={classes.paper}>
                <h1>נתוני קורונה לפי עיר</h1>
                <GoogleAd slot="3697863434" />
                {/*<ins crossorigin class="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-8931125519629655"
                    data-ad-slot="3697863434"
                    data-ad-format="auto"
data-full-width-responsive="true"></ins>*/}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Chart data={selectedCities} />
                    </Grid>
                    <Grid item xs={12}>

                        <Cities onSelectionChange={(rows) => setSelectedCities(rows)} />
                    </Grid>
                </Grid>
            </Paper>
            <Box pt={4}>
                <Copyright />
            </Box>
        </Container>
    </main>;
};

export default Main;