import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  Title,
  Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ArgumentScale, Animation } from '@devexpress/dx-react-chart';
import {
  curveCatmullRom,
  line,
} from 'd3-shape';
import { scaleTime } from 'd3-scale';

import TodayTwoToneIcon from '@material-ui/icons/TodayTwoTone';
import ColorizeTwoToneIcon from '@material-ui/icons/ColorizeTwoTone';
import GroupTwoToneIcon from '@material-ui/icons/GroupTwoTone';

import {datesAreOnSameDay} from '../../helpers/dates'

const Line = props => (
  <LineSeries.Path
    {...props}
    path={line()
      .x(({ arg }) => arg)
      .y(({ val }) => val)
      .curve(curveCatmullRom)}
  />
);

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const titleStyles = {
  title: {
    textAlign: 'center',
    width: '100%',
    marginBottom: '10px',
  },
};
const Text = withStyles(titleStyles)((props) => {
  const { text, classes } = props;
  const [mainText, subText] = text.split('\\n');
  return (
    <div className={classes.title}>
      <Typography component="h3" variant="h5">
        {mainText}
      </Typography>
      <Typography variant="subtitle1">{subText}</Typography>
    </div>
  );
});

const legendStyles = () => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  },
});
const legendLabelStyles = theme => ({
  label: {
    marginBottom: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
});
const legendItemStyles = () => ({
  item: {
    flexDirection: 'column-reverse',
  },
});

const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const legendItemBase = ({ classes, ...restProps }) => (
  <Legend.Item className={classes.item} {...restProps} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);
const Item = withStyles(legendItemStyles, { name: 'LegendItem' })(legendItemBase);

const Active = (props) => {
  const { data } = props;

  const [dataField, setDataField] = useState(0)

  const map = new Map();

  const handleChange = (event, newDataField) => {
    setDataField(newDataField);
  };

  data.forEach(city => {
    if (dataField === 3) {
      let filteredHistory = city.history.reduce((a, sample) => {
        if (a.length) {
          if(!datesAreOnSameDay(a[a.length-1].timestamp.toDate(), sample.timestamp.toDate())) {
            a.push(sample);
          }
          else {
            a[a.length-1] = sample;
          }
        } else a.push(sample);
        
        return a;
      }, []);

      filteredHistory.forEach((item, index) => {
        const obj = { timestamp: item.timestamp.toDate() };
        if(index !== 0)
        {
          obj[city.name] = item.sickCount - filteredHistory[index-1].sickCount;
          map.set(item.timestamp, { ...map.get(item.timestamp), ...obj })
        }
      })
    }
    else {
      city.history.forEach((item, index) => {
        const obj = { timestamp: item.timestamp.toDate() };
        if (dataField === 0) {
          obj[city.name] = Number.isNaN(item.actualSick) ? 0 : item.actualSick;
        } else if (dataField === 1) {
          obj[city.name] = Number.isNaN(item.verifiedLast7Days) ? 0 : item.verifiedLast7Days;
        } else if (dataField === 2) {
          const verifiedPrecent = item.verifiedLast7Days * 100 / item.testLast7Days;
          obj[city.name] = Number.isNaN(verifiedPrecent) ? 0 : verifiedPrecent;
        }
        map.set(item.timestamp, { ...map.get(item.timestamp), ...obj })
      });
    }
  });

  const chartData = Array.from(map.values());

  const lines = data.map(city => <LineSeries key={city.name}
    name={city.name}
    valueField={city.name}
    argumentField="timestamp"
    
  />)
  /*seriesComponent={Line}*/
  return (
    <Paper >
      <AppBar position="static">
        <Tabs value={dataField} onChange={handleChange} aria-label="simple tabs example">
          <Tab icon={<GroupTwoToneIcon />} label="מספר חולים פעילים" {...a11yProps(0)} />
          <Tab icon={<TodayTwoToneIcon />} label="חולים חדשים בשבוע האחרון" {...a11yProps(1)} />
          <Tab icon={<ColorizeTwoToneIcon />} label="אחוז בדיקות חיוביות" {...a11yProps(2)} />
          <Tab icon={<TodayTwoToneIcon />} label="חולים חדשים ביום" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <Chart height="400"
        data={chartData}
        key={data.length*chartData.length}
      >
        <ArgumentScale factory={scaleTime} />
        <ArgumentAxis />
        <ValueAxis />

        {lines}

        <Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />

        <Animation />
      </Chart>
    </Paper>
  );
}

export default Active;