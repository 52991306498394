import firebase from "firebase/app";
import "firebase/firestore";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDQYbUtwNJGGbFteKZeMNN7rx0P-AWsM4g",
    authDomain: "covid19il.firebaseapp.com",
    databaseURL: "https://covid19il.firebaseio.com",
    projectId: "covid19il",
    storageBucket: "covid19il.appspot.com",
    messagingSenderId: "356605229461",
    appId: "1:356605229461:web:c32e73f53f45a7dcdade9c",
    measurementId: "G-8KME2RXXF6"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export default firebase;